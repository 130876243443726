<template>
	<div class="main-contents">
		<div class="tit">탈퇴회원</div>
		<div class="search-box interval">
			<SelectComp class="wd02" list="M:남자,F:여자" v-model="srchFilter.srchGender" title="성별" :isAll="true" /><nbsp/>
			<template  v-if="viewNm == 'MEM914M01'">
				<SelectComp class="wd05" cdId="PRO102" v-model="srchFilter.srchEmpDivCd" title="직원구분" :isAll="true" /><nbsp/>
			</template>
			<template  v-if="viewNm == 'MEM926M01'">
				<SelectComp list="21:관리총괄,22:프로젝트담당자" v-model="srchFilter.srchMberDivCd" title="회원구분" :isAll="true"/><nbsp/>
			</template>
			<nbsp/>
			<InputComp v-model="srchFilter.srchTxt" :placeholder="placeholderTxt" @keyup.enter="getWithdrawalMberList('1')"/>
			<img class="search_btn" src="/images/admin/search_btn.png" alt="검색버튼" @click="getWithdrawalMberList('1')"/>
		</div>
		<!-- 탈퇴회원 리스트 -->
		<div class="Board type3 sp exit">
			<table class="Board_type3 admin">
				<colgroup>
					<col width="4%">
					<col width="8%">
					<col width="8%">
					<col :width="viewNm == 'MEM933M01' ? '24%' : '20%'">
					<col width="4%">
					<col width="4%">
					<col :width="viewNm == 'MEM933M01' ? '24%' : '20%'">
					<col width="8%" v-if="'MEM914M01,MEM926M01'.indexOf(viewNm) > -1">
					<col width="10%">
					<col width="10%">
				</colgroup> 
				<thead>
					<tr>
						<th>NO</th>
						<th>회원명</th>
						<th>회원번호</th>
						<th>아이디</th>
						<th>성별</th>
						<th>연생</th>
						<th>{{placeholderTxt}}</th>
						<th v-if="viewNm == 'MEM914M01'">직원구분</th>
						<th v-if="viewNm == 'MEM926M01'">회원구분</th>
						<th>회원가입일</th>
						<th>회원탈퇴일</th>
					</tr>
				</thead>
				<tbody>
					<template v-if="withMberList.length > 0">
						<tr v-for="(row, rowIdx) in withMberList" :key="rowIdx" :class="row.newYn == 'Y' ? 'new' : ''">
							<td class="score"><div class="new" v-if="row.newYn == 'Y'">new</div>{{(pageInfo.pageIndex - 1) * pageInfo.pageUnit + (rowIdx + 1)}}</td>
							<td class="score">{{row.mberNm}}</td>
							<td class="score">{{row.mberSeq}}</td>
							<td class="score">{{row.loginId}}</td>
							<td class="score">{{row.gender | gender}}</td>
							<td class="score">{{row.birthYyyymmdd | birthYear}}</td>
							<td class="score">{{row.corpNm}}</td>
							<td class="score" v-if="'MEM914M01,MEM926M01'.indexOf(viewNm) > -1"><SelectComp type="text" cdId="PRO102" v-model="row.empDivCd" /></td>
							<td class="score">{{row.regYmd | date('yyyy.MM.dd')}}</td>
							<td class="score">{{row.withdrawalYyyymmdd | date('yyyy.MM.dd')}}</td>
						</tr>
					</template>
					<template v-else>
						<!-- 관련내용 없을 경우 -->
						<tr>
							<td colspan="10" class="none">탈퇴회원이 없습니다!</td>
						</tr>
					</template>
				</tbody>
			</table>
		</div>

		<!-- 페이징부분 -->
		<pagingComp :pageInfo="pageInfo" @page-click="goPage"/>
	</div>
</template>

<script>
import pagingComp from '@/components/PagingComp.vue';

export default {
	data() {
		return {
			srchFilter : {
				srchGender : '',
				srchEmpDivCd : '',
				srchMberDivCd : '',
				srchTxt : '',
				pageIndex : 1,
				mberDiv : '',
			},

			withMberList : [],
			pageInfo : {},

			placeholderTxt : '',
			viewNm : '',
			
		}
	},

	components : {
		pagingComp
	},

	mounted() {
		this.viewNm = this.$route.name;

		if(this.viewNm == 'MEM914M01') {
			this.srchFilter.mberDiv = '11';
			this.placeholderTxt = '소속회사명';
		}else if(this.viewNm == 'MEM926M01') {
			this.srchFilter.mberDiv = '2';
			this.placeholderTxt = '수요기업명';
		}else if(this.viewNm == 'MEM933M01') {
			this.srchFilter.mberDiv = '31';
			this.placeholderTxt = '공급기업명';
		}

		this.getWithdrawalMberList();
	},

	methods : {
		getWithdrawalMberList(div) {
			var param = this.srchFilter;
			param.pageUnit = '25';
			param.pageSize = '10';
			if(div) param.pageIndex = 1;

			this.$.httpPost('/api/mem/adm/tec/getWithdrawalMberList', param)
				.then(res => {
					this.withMberList = res.data.withMberList;
					this.pageInfo = res.data.pageInfo;
				}).catch(this.$.httpErrorCommon);
		},

		// 페이징 이동
		goPage(page) {
			this.srchFilter.pageIndex = page;
			this.getAdmDcoPgmMberList();
		},
	}
}
</script>